@import url(https://fonts.googleapis.com/css2?family=Arvo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
span {
  font-family: 'Arvo', sans-serif !important;
  /* font-family: 'Poppins', sans-serif !important; */
}

